import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NowPage = () => {
    return (
        <Layout pageMeta={{ title: "What I'm Doing" }}>  

        <h1 class="text-2xl">What I'm Doing</h1>
        <br />
        <p class="text-lg">What I'm up to lately:</p>
        <br />
        <ul class="list-disc ml-8">
          <li>Enjoying the Summer!</li>
        </ul>
        <br />
        <p class="text-sm">Last Updated: July 2023</p>
      </Layout>
    )
  }

export default NowPage
